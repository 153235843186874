import tinymce from 'tinymce';

import 'tinymce/models/dom/model';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';

import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';


document.addEventListener("turbo:load", function() {
    $('.tinymce').each(function (index, element) {

        tinymce.init({
            selector: '.tinymce',
            plugins: 'code fullscreen link lists preview table', // Corrected plugins
            content_css: false,
            skin: false,
            height: "200",
            width: "50%",
            autoresize: false
        });
    });
});

